/* Base styles */
.home-container {
    overflow-x: hidden;
}

.main-circle-container-topleft {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -998;
}

.circle-one, .circle-two, .circle-three {
    position: absolute;
    border: 1.5px solid rgba(193, 193, 193, 0.5);
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.circle-one {
    height: 450px;
    width: 450px;
    top: -100px;
    left: -150px;
}

.circle-two {
    height: 475px;
    width: 475px;
    top: -135px;
    left: -135px;
}

.circle-three {
    height: 550px;
    width: 550px;
    top: -130px;
    left: -150px;
}

.header-container-home-page {
    min-height: 100vh;
    width: 90%;
    gap: 50px;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;
}

.left-text-container-home {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 1;
    text-align: left;
    gap: 30px;
    max-width: 50%;
}

.left-text-container-home h2 {
    font-size: 3.5vw;
    font-family: "David Libre", system-ui;
    font-weight: 700;
    width: 100%;
    margin: 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.left-text-container-home p {
    font-family: 'Inter', sans-serif;
    font-size: 1.1vw;
    color: #5C6574;
    margin: 0;
}

.two-buttons-left-container-home {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.two-buttons-left-container-home button {
    background-color: #EA6D27;
    color: white;
    border: none;
    padding: 15px 20px;
    border-radius: 15px 4px 15px 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.two-buttons-left-container-home button:hover {
    background-color: #101A24;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.two-buttons-left-container-home button:nth-child(1) {
    background-color: #101A24;
}

.two-buttons-left-container-home button:nth-child(1):hover {
    background-color: #EA6D27;
}

.svgs-container-left-home {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.svgs-container-left-home svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.svgs-container-left-home svg:hover {
    transform: scale(1.1);
}

.right-container-with-images {
    flex: 1;
    position: relative;
    max-width: 50%;
    height: 0;
    padding-bottom: 50%;
}

.img-background-home {
    position: absolute;
    top: 18px;
    left: 50px;
    width: 55%;
    height: 100%;
    z-index: -1;
}

.img-background-home img {
    width: 120%;
    height: 120%;
    object-fit: contain;
    margin-top: -100px;
}
.video-container{
    width:55%;
}

.video-container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px 40px 100px 40px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.counter-container-menu {
    text-align: center;
    margin: 50px 0;
}

.counter-container-menu h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.counter-container-menu p {
    font-size: 1.2rem;
    color: #5C6574;
}

/* Media Queries */
@media (max-width: 1199px) {
    .left-text-container-home {
        max-width: 60%;
    }

    .right-container-with-images {
        max-width: 40%;
    }

    .left-text-container-home h2 {
        font-size: 2.5rem;
    }

    .left-text-container-home p {
        font-size: 1rem;
    }

    .two-buttons-left-container-home button {
        padding: 12px 16px;
        font-size: 0.9rem;
    }

    .circle-one, .circle-two, .circle-three {
        left: -100px;
    }

    .circle-one {
        height: 350px;
        width: 350px;
    }

    .circle-two {
        height: 375px;
        width: 375px;
    }

    .circle-three {
        height: 450px;
        width: 450px;
    }
}

@media (max-width: 900px) {
    .header-container-home-page {
        flex-direction: column;
        align-items: center;
    }

    .left-text-container-home,
    .right-container-with-images {
        max-width: 80%;
    }

    .left-text-container-home {
        text-align: center;
        align-items: center;
    }

    .right-container-with-images {
        margin-top: 40px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .left-text-container-home h2 {
        font-size: 2.2rem;
    }

    .left-text-container-home p {
        font-size: 0.95rem;
    }

    .two-buttons-left-container-home {
        justify-content: center;
    }

    .circle-one, .circle-two, .circle-three {
        left: -75px;
    }
    .circle-one {
        height: 300px;
        width: 300px;
    }

    .circle-two {
        height: 325px;
        width: 325px;
    }

    .circle-three {
        height: 400px;
        width: 400px;
    }
    .img-background-home {
        display: none;
    }
}

@media (max-width: 767px) {
    .header-container-home-page {
        width: 95%;
    }

    .left-text-container-home,
    .right-container-with-images {
        max-width: 100%;
    }

    .right-container-with-images {
        padding-bottom: 0%;
        height: auto;
        margin-top: 10px;
    }

    .left-text-container-home h2 {
        font-size: 2rem;
    }

    .left-text-container-home p {
        font-size: 0.9rem;
    }

    .two-buttons-left-container-home {
        flex-direction: row;
        align-items: center;
        width: 60%;
    }

    .two-buttons-left-container-home button {
        width: 100%;
        font-size: 0.8rem;
    }

    .circle-one, .circle-two, .circle-three {
        left: -50px;
    }

    .circle-one {
        height: 250px;
        width: 250px;
    }

    .circle-two {
        height: 275px;
        width: 275px;
    }

    .circle-three {
        height: 350px;
        width: 350px;
    }

    .video-container video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px 10px 10px 10px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
    .header-container-home-page{
        padding: 10px 0;
    }
}

@media (max-width: 479px) {
    .left-text-container-home h2 {
        font-size: 1.8rem;
    }

    .left-text-container-home p {
        font-size: 0.8rem;
    }

    .two-buttons-left-container-home button {
        font-size: 0.7rem;
        padding: 10px 12px;
    }

    .circle-one, .circle-two, .circle-three {
        left: -30px;
    }

    .circle-one {
        height: 200px;
        width: 200px;
    }

    .circle-two {
        height: 225px;
        width: 225px;
    }

    .circle-three {
        height: 275px;
        width: 275px;
    }

    .right-container-with-images {
        padding-bottom: 0%;
    }



    .img-background-home {
        top: 10px;
        left: 25px;
        width: 70%;
    }

    .counter-container-menu h1 {
        font-size: 1.8rem;
    }

    .counter-container-menu p {
        font-size: 1rem;
    }
    .header-container-home-page{
        gap: 10px;
    }
}