@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.main-container-div-menuslider{
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper {
  display: flex;
  max-width: 1200px;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 40px;
}

.wrapper i {
  top: 50%;
  height: 44px;
  width: 44px;
  color: #000;
  cursor: pointer;
  font-size: 1.15rem;
  position: absolute;
  text-align: center;
  line-height: 44px;
  background: #fff;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: transform 0.1s linear;
  box-shadow: 0 3px 6px rgba(0,0,0,0.23);
}

.wrapper i:active {
  transform: translateY(-50%) scale(0.9);
}

.wrapper i:hover {
  background: #f2f2f2;
}

.wrapper i:first-child {
  left: 0;
}

.wrapper i:last-child {
  right: 0;
}

.wrapper .carousel {
  font-size: 0px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.carousel::-webkit-scrollbar {
  display: none;
}

.carousel.dragging {
  cursor: grab;
  scroll-behavior: auto;
}

.carousel.dragging img {
  pointer-events: none;
}

.carousel img {
  height: 340px;
  object-fit: cover;
  user-select: none;
  margin-right: 14px;
  width: calc(100% / 3);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.carousel img:last-child {
  margin-right: 0px;
}

@media screen and (max-width: 900px) {
  .carousel img {
    width: calc(100% / 2);
  }
}

@media screen and (max-width: 550px) {
  .carousel img {
    width: 60%;
  }
  .wrapper{
    padding: 0;
    width: 100%;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.wrapper i {
  animation: fadeIn 0.3s ease-in-out;
}