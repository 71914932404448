.main-navbar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 80px;
  }
  .right-container-navbar a{
    color: white;
    text-decoration: none;
  }
  .right-container-navbar a:hover{
    color: black;
  }
  .img-left-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .img-left-container h6 {
    width: 80%;
    font-size: 24px;
    font-family: "David Libre", system-ui;
    font-weight: 700;
    font-style: normal;
  }
  
  .central-container-navbar nav ul {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .central-container-navbar li {
    padding: 10px;
    list-style: none;
  }
  
  .central-container-navbar li a {
    color: black;
    text-decoration: none;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    transition: color 0.3s ease-in-out;
  }
  
  .central-container-navbar li a.active {
    color: #EA6D27;
  }
  
  .right-container-navbar button {
    padding: 20px;
    background-color: #EA6D27;
    border: none;
    color: white;
    border-radius: 15px 0px 15px 0px;
    cursor: pointer;
    font-size: 20px;
    font-family: "David Libre", system-ui;
    transition: all 0.3s ease;
  }
  
  .right-container-navbar button:hover {
    background-color: white;
    color: black;
    border: 3px solid #EA6D27;
  }
  
  .mobile-contact-button {
    display: none;
  }
  
  .hamburger-container {
    display: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    position: relative;
    z-index: 1001; /* Ensure hamburger stays above all other components */
  }
  
  .hamburger {
    width: 30px;
    height: 3px;
    background-color: #333;
    border-radius: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out;
  }
  
  .hamburger::before,
  .hamburger::after {
    content: '';
    width: 30px;
    height: 3px;
    background-color: #333;
    border-radius: 3px;
    position: absolute;
    transition: all 0.3s ease-in-out;
  }
  
  .hamburger::before {
    transform: translateY(-10px);
  }
  
  .hamburger::after {
    transform: translateY(10px);
  }
  
  /* Hamburger open state */
  .hamburger.open {
    background-color: transparent;
  }
  
  .hamburger.open::before {
    transform: rotate(45deg);
  }
  
  .hamburger.open::after {
    transform: rotate(-45deg);
  }
  
  @media (max-width: 768px) {
    .main-navbar-container {
      margin: 10px 20px;
    }
  
    .central-container-navbar {
      position: fixed;
      top: 0;
      right: -100%;
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: -5px 0px 15px rgba(0, 0, 0, 0.1);
      height: 100vh;
      width: 80%;
      max-width: 300px;
      transition: right 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
      z-index: 1000; /* Ensure menu stays above all other components */
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  
    .central-container-navbar.open {
      right: 0;
    }
  
    .central-container-navbar nav {
      width: 100%;
    }
  
    .central-container-navbar nav ul {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 0;
    }
  
    .central-container-navbar li {
      width: 100%;
      text-align: center;
      opacity: 0;
      transform: translateX(50px);
      transition: opacity 0.5s ease, transform 0.5s ease;
    }
  
    .central-container-navbar.open li {
      opacity: 1;
      transform: translateX(0);
    }
  
    .central-container-navbar li:nth-child(1) { transition-delay: 0.1s; }
    .central-container-navbar li:nth-child(2) { transition-delay: 0.2s; }
    .central-container-navbar li:nth-child(3) { transition-delay: 0.3s; }
    .central-container-navbar li:nth-child(4) { transition-delay: 0.4s; }
  
    .central-container-navbar li a {
      display: block;
      padding: 15px;
      font-size: 18px;
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    }
  
    .central-container-navbar li a:hover,
    .central-container-navbar li a.active {
      background-color: #EA6D27;
      color: white;
    }
  
    .hamburger-container {
      display: block;
      z-index: 1;
    }
  
    .right-container-navbar {
      display: none;
    }
  
    .mobile-contact-button {
      display: block;
      margin-top: 30px;
      text-align: center;
      opacity: 0;
      transform: translateY(20px);
      transition: opacity 0.5s ease, transform 0.5s ease;
      transition-delay: 0.5s;
    }
  
    .central-container-navbar.open .mobile-contact-button {
        opacity: 1;
        transform: translateY(0);
      }
  
      .mobile-contact-button button {
        padding: 15px 30px;
        background-color: #EA6D27;
        border: none;
        color: white;
        border-radius: 15px 0px 15px 0px;
        cursor: pointer;
        font-size: 18px;
        font-family: "David Libre", system-ui;
        transition: all 0.3s ease;
      }
  
      .mobile-contact-button button:hover {
        background-color: white;
        color: black;
        border: 3px solid #EA6D27;
      }
  }
  
  @media (max-width: 400px) {
      .img-left-container h6 {
          font-size: 18px;
      }
  }
  