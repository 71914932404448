.fixed {
  position: fixed;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.bg-white {
  background-color: white;
}

.overflow-hidden {
  overflow: hidden;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100vh;
}

.border-circle {
  width: 150px;
  height: 150px;
  border: 5px solid #FF3C38;
  border-top-color: transparent;
  border-radius: 50%;
  position: absolute;
  z-index: 10; /* Ensures the circle is above the SVG text */
  top: 36%;
  left: 43.5%;
  transform: translate(-50%, -50%);
}
