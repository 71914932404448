/* Global Styles */
body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #333333;
  }
  
  .about-container {
    padding: 60px 20px;
    background-color: #f4f4f4;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  
  .about-content {
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
  }
  
  .about-title {
    font-size: 3rem;
    color: #e74c3c;
    margin-bottom: 30px;
    font-weight: 700;
    letter-spacing: 1px;
  }
  
  .about-description {
    font-size: 1.125rem;
    margin-bottom: 20px;
    line-height: 1.75;
    color: #555555;
  }
  
  .highlight {
    color: #e74c3c;
    font-weight: 600;
  }
  
  .cta-container {
    margin-top: 40px;
  }
  
  .cta-button {
    padding: 12px 28px;
    background-color: #e74c3c;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 1rem;
    border: 2px solid transparent;
    border-radius: 50px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease;
    display: inline-block;
  }
  
  .cta-button:hover {
    background-color: #ffffff;
    color: #e74c3c;
    border-color: #e74c3c;
    transform: translateY(-2px);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .about-container {
      padding: 40px 15px;
    }
  
    .about-title {
      font-size: 2.5rem;
    }
  
    .about-description {
      font-size: 1rem;
    }
  
    .cta-button {
      font-size: 0.9rem;
      padding: 10px 24px;
    }
  }
  
  @media (max-width: 480px) {
    .about-title {
      font-size: 2rem;
    }
  
    .about-description {
      font-size: 0.95rem;
    }
  }
  