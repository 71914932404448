.gallery-container {
  padding: 50px 20px;
  text-align: center;
  background-color: #f9f9f9; /* Soft background */
}

.gallery-container h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 40px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.gallery-grid {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 25px; /* Improved spacing */
}

.gallery-image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%; /* Aspect ratio */
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
  transition: box-shadow 0.3s ease;
}

.gallery-image:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* On hover, deeper shadow */
}

.gallery-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.gallery-image:hover img {
  transform: scale(1.08); /* Slight zoom on hover */
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Smaller columns for mobile */
    gap: 15px;
  }

  .gallery-image {
    padding-bottom: 100%; /* Square ratio for mobile */
  }

  .gallery-container h1 {
    font-size: 2rem;
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Larger columns for desktop */
    gap: 35px;
  }

  .gallery-image {
    padding-bottom: 66.67%; /* 3:2 aspect ratio for large screens */
  }

  .gallery-container h1 {
    font-size: 3rem;
    margin-bottom: 50px;
  }
}
