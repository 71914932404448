.main-menu-container-page {
    padding: 30px 10px;
    max-width: 90%;
    margin: 0 auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
  }
  
  .top-container-menu {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .top-container-menu h1 {
    font-size: 3rem;
    margin-bottom: 10px;
    color: #222;
  }
  
  .top-container-menu h6 {
    font-size: 1.2rem;
    color: #777;
    margin-bottom: 30px;
  }
  
  .top-container-menu a {
    text-decoration: none;
    color: #fff;
    background-color: #ff7f50;
    font-weight: 600;
    font-size: 1.1rem;
    padding: 10px 25px;
    border-radius: 25px;
    transition: background-color 0.3s ease;
  }
  
  .top-container-menu a:hover {
    background-color: #ff5722;
  }
  
  /* Platter section */
  .top-buffet-platter {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .platter-container {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 24%; /* Set width to ensure 4 platters fit in one row */
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .platter-container:hover {
    transform: translateY(-12px);
    box-shadow: 0px 16px 30px rgba(0, 0, 0, 0.15);
  }
  
  /* Image styling for platters */
  .platter-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  /* Platter details section */
  .platter-details {
    padding: 15px;
    flex-grow: 1;
  }
  
  .platter-name {
    font-size: 1.6rem;
    margin-bottom: 15px;
    font-weight: bold;
    color: #444;
  }
  
  /* List styling */
  .platter-details p {
    margin: 10px 0 5px;
    font-weight: bold;
    color: #555;
  }
  
  .platter-details ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 10px;
  }
  
  .platter-details li {
    margin-bottom: 8px;
    font-size: 0.9rem;
    color: #666;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 1200px) {
    .platter-container {
      width: 48%; /* Two platters per row on medium screens */
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .top-container-menu h1 {
      font-size: 2rem;
    }
  
    .top-container-menu h6 {
      font-size: 0.95rem;
    }
  
    .platter-container {
      width: 100%; /* One platter per row on small screens */
      margin-bottom: 20px;
    }
  
    .top-buffet-platter {
      flex-direction: column;
      align-items: center;
    }
  }
  
  @media (max-width: 480px) {
    .main-menu-container-page {
      padding: 20px 10px;
    }
  
    .top-container-menu h1 {
      font-size: 1.7rem;
    }
  
    .top-container-menu h6 {
      font-size: 0.85rem;
    }
  
    .top-container-menu a {
      font-size: 0.9rem;
      padding: 8px 20px;
    }
  
    .platter-details {
      padding: 15px;
    }
  
    .platter-name {
      font-size: 1.4rem;
    }
  
    .platter-details li {
      font-size: 0.85rem;
    }
  }
  