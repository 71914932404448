.counter-container-menu {
    text-align: center;
    padding: 20px;
    background-color: #f8f9fa;
    color: #333;
  }
  
  .counter-container-menu h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    color: #ff6600;
  }
  
  .counter-container-menu p {
    font-size: 1.5rem;
    color: #333;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .counter-container-menu h1 {
      font-size: 2.5rem;
    }
  
    .counter-container-menu p {
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 480px) {
    .counter-container-menu h1 {
      font-size: 2rem;
    }
  
    .counter-container-menu p {
      font-size: 1rem;
    }
  }
  