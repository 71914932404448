
  
  .contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 40px;
  }
  
  .contact-content {
    max-width: 700px;
    width: 100%;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .contact-title {
    font-size: 2.5rem;
    color: #000000; /* Black text */
    margin-bottom: 20px;
    text-align: center;
    font-weight: 700;
  }
  
  .contact-description {
    font-size: 1.125rem;
    margin-bottom: 30px;
    text-align: center;
    color: #555555; /* Dark gray text */
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-size: 1rem;
    margin-bottom: 5px;
    color: #000000; /* Black text */
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .form-group textarea {
    resize: vertical;
  }
  
  .submit-button {
    padding: 12px 20px;
    background-color: #e67e22; /* Orange background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-align: center;
    display: block;
    margin: 0 auto;
  }
  
  .submit-button:hover {
    background-color: #d35400; /* Darker orange */
    transform: translateY(-2px);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .contact-title {
      font-size: 2rem;
    }
  
    .contact-description {
      font-size: 1rem;
    }
  
    .submit-button {
      font-size: 0.9rem;
      padding: 10px 18px;
    }
  }
  