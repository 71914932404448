.main-display-container-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px 5%;
    background-color: #fff;
    font-family: 'Inter', sans-serif;
    position: relative;
    margin-top: 50px;
  }
  
  .left-container-footer {
    flex: 0 0 40%;
  }
  
  .left-container-footer h1 {
    font-family: "David Libre", serif;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .left-container-footer p {
    margin: 0 0 15px;
    max-width: 400px;
    font-size: 14px;
    line-height: 1.5;
    color: #5C6574;
  }
  
  .opening-hours-footer {
    margin-top: 20px;
  }
  
  .opening-hours-footer h6 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
  }
  
  .opening-hours-footer p {
    margin: 5px 0;
    font-size: 14px;
  }
  
  .container-middle-navigation-footer,
  .clasname-direct-contact-footer {
    flex: 0 0 25%;
  }
  
  .container-middle-navigation-footer h6,
  .clasname-direct-contact-footer h6 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #333;
  }
  
  .container-middle-navigation-footer p {
    margin: 10px 0;
    font-family: "David Libre", serif;
    font-size: 14px;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .container-middle-navigation-footer p:hover {
    color: #007bff;
  }
  
  .clasname-direct-contact-footer svg {
    width: 24px;
    height: 24px;
    margin-right: 15px;
    margin-top: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .clasname-direct-contact-footer svg:hover {
    transform: scale(1.1);
  }
  
  @media (max-width: 1024px) {
    .main-display-container-footer {
      flex-wrap: wrap;
    }
  
    .left-container-footer {
      flex: 0 0 100%;
      margin-bottom: 30px;
    }
  
    .container-middle-navigation-footer,
    .clasname-direct-contact-footer {
      flex: 0 0 45%;
    }
  }
  
  @media (max-width: 768px) {
    .main-display-container-footer {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .left-container-footer,
    .container-middle-navigation-footer,
    .clasname-direct-contact-footer {
      flex: 0 0 100%;
      margin-bottom: 30px;
    }
  
    .left-container-footer p {
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .main-display-container-footer {
      padding: 30px 5%;
    }
  
    .left-container-footer h1 {
      font-size: 24px;
    }
  
    .left-container-footer p,
    .opening-hours-footer p,
    .container-middle-navigation-footer p {
      font-size: 12px;
    }
  
    .container-middle-navigation-footer h6,
    .clasname-direct-contact-footer h6 {
      font-size: 14px;
    }
  
    .clasname-direct-contact-footer svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }